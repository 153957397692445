



















































import { defineComponent, reactive } from '@vue/composition-api'
import NbTextbox from '@/components/NbTextbox.vue'

export default defineComponent({
	name: 'LoginPage',

	components: {
		NbTextbox
	},

	props: {},

	setup(props, { root }) {
		const state = reactive({
			username: '',
			password: '',
			rememberMe: false
		})

		const loginUser = () => {
			if (state.username == 'admin' && state.password == 'admin') {
				const loginInfo = root.$store.state.loginInfo;

				loginInfo.firstName = 'Administrator'

				root.$store.dispatch('updateLoginInfo', loginInfo)

				const goto = (root.$route.query.goto as string | null) || '/my-neobanx';

				root.$router.push(goto)
			}
			else {
				root.$q.notify({
					message: 'Invalid username or password',
					color: 'red-8',
					actions: [{ label: 'DISMISS', color: 'red-2' }],
					icon: 'close',
					html: true,
					timeout: 10000
				})
			}
		}

		return { state, loginUser }
	}
})
